import { Card, Grid, Button } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { CollectionIcon, InfoIcon } from '@enterprise-ui/icons'
import { ContentPlaceholder } from './ContentPlaceholder'
export const ItemProducts = ({ appMenus, auth }) => {
  const userTypes = auth?.session?.identity?.business_category || 'I'
  return (
    <Card className="hc-pa-md hc-bg-contrast-weak home-app-cards">
      <p
        className="hc-fs-md font-color-default hc-lh-expanded hc-mt-dense font-weight-700"
        style={{ display: 'flex' }}
      >
        <EnterpriseIcon icon={CollectionIcon} className="hc-mr-dense" />{' '}
        <h3 className="hc-fs-md font-color-default hc-lh-expanded font-weight-700">
          Quick access to Item management products
        </h3>
      </p>
      {appMenus.length === 0 ? (
        <ContentPlaceholder length={1} />
      ) : (
        <Grid.Container>
          {appMenus.map((app, index) => (
            <Grid.Item md={6} xs={12} key={index} className="display-flex">
              <Card
                className="hc-pa-md hc-bg-contrast-weak font-color-default app-details-card"
                padding="dense"
              >
                <h4 className="hc-fs-md hc-mb-normal font-weight-500">
                  {app.name}
                </h4>
                <h5
                  className="hc-fs-sm hc-mb-normal app-description font-weight-400"
                  style={{ minHeight: 85 }}
                  dangerouslySetInnerHTML={{
                    __html: app.productDescription,
                  }}
                />
                <div className="app-section-actionbar">
                  {userTypes === 'E' && (
                    <Button
                      as="a"
                      href={app.externalHelpResource}
                      target="_blank"
                      type="ghost"
                      className="hc-fs-sm hc-clr-interactive hc-pl-none font-weight-500 info-training-button"
                      data-testid="info-and-training"
                      aria-label={`Information and training for ${app.name}`}
                    >
                      Info & Training{' '}
                      <EnterpriseIcon
                        icon={InfoIcon}
                        size="sm"
                        className="hc-ma-dense"
                      />
                    </Button>
                  )}
                  {userTypes === 'I' && (
                    <Button
                      as="a"
                      href={app.internalHelpResource}
                      target="_blank"
                      type="ghost"
                      className="hc-fs-sm hc-clr-interactive hc-pl-none font-weight-500 info-training-button"
                      data-testid="info-and-training"
                      aria-label={`Information and training for ${app.name}`}
                      disabled={app.internalHelpResource === ''}
                    >
                      Info & Training{' '}
                      <EnterpriseIcon
                        icon={InfoIcon}
                        size="sm"
                        className="hc-ma-dense"
                      />
                    </Button>
                  )}
                  <Button
                    as="a"
                    target="_self"
                    href={app.path}
                    type="primary"
                    aria-label={`Launch ${app.name} application`}
                  >
                    Launch
                  </Button>
                </div>
              </Card>
            </Grid.Item>
          ))}
        </Grid.Container>
      )}
    </Card>
  )
}
