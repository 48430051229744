/* eslint-disable array-callback-return */
import axios from 'axios'
import apiConfig from '../globalConfig/apiConfig'
export const fetchProductDetailsAndMenus = async (auth) => {
  try {
    const response = await axios.get(
      `${apiConfig?.strapi?.base}${apiConfig?.strapi?.productMenuDetails}`,
      {
        headers: {
          Authorization: process.env.REACT_APP_STRAPI_AUTH_TOKEN_CODE,
        },
      },
    )

    const userType = auth?.session?.identity?.business_category || 'I'
    const memberOfLists = auth?.session?.userInfo?.memberOf || []
    const menuDetails =
      response?.data?.data.map(
        (item) => item?.attributes?.Application_details,
      ) || []

    const allowedMenus = menuDetails?.filter((menu) =>
      menu?.allowedUserTypes.includes(userType),
    )

    const memberAccess = allowedMenus
      ?.filter((menu) =>
        memberOfLists.some(
          (item) =>
            menu?.membersAccessGroup?.some(
              (prefix) => item === prefix || item.startsWith(prefix),
            ) || menu?.membersAccessGroup?.length === 0,
        ),
      )
      .sort((a, b) => (a.displayOrder || 0) - (b.displayOrder || 0))
    return memberAccess
  } catch (error) {
    console.error('Error fetching product details and menus:', error)
    return {
      message: 'Something went wrong while fetching product details!',
    }
  }
}

export const fetchProductReleaseNotes = async (auth) => {
  try {
    const response = await axios.get(
      `${apiConfig.strapi.base}${apiConfig.strapi.productsRelease}`,
      {
        headers: {
          Authorization: process.env.REACT_APP_STRAPI_AUTH_TOKEN_CODE,
        },
      },
    )

    const userTypes = auth?.session?.identity?.business_category || 'I'
    const allowedReleaseContent = response?.data?.data?.filter(
      (releaseContent) => {
        const allowedUserTypes = releaseContent?.attributes?.AllowedUserTypes
        return allowedUserTypes === userTypes || allowedUserTypes === 'Both'
      },
    )

    return allowedReleaseContent
  } catch (error) {
    console.error('Error fetching product release notes:', error)
    return {
      message: 'Something went wrong while fetching product release notes!',
    }
  }
}
